@import 'css/index.css';
@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&family=Roboto+Slab:wght@300&display=swap');

body, html, #root {
  height: 100%;
}

:root{
  --white: white;
  --primary_color: white;
  --secondary_color: #b7b7b7;
  --tertiary_color: #848484;
  --tetra_color: #5a5a5a;
  --hex_color: #2e2e2e;
  --black: black;
  --primary_font: 'Roboto Slab', serif;
  --secondary_font: 'Bakbak One', cursive;

  --top_nav_color: "#01B2F7"

}

