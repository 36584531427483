@import url(https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bakbak+One&family=Roboto+Slab:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.top{
    box-shadow: 0 0 5px 1px rgb(0, 0, 0);
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    z-index: 1;
    width: 100%;

}

.top_nav{
    padding: 1rem 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top_nav-logo{
    max-width: 200px;
}

.top_nav-logo img{
    width: 100%;
    object-fit: fill;
}

.top .top_nav .top_nav-navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top .top_nav .top_nav-navbar a{
    font-family: var(--secondary_font);
    font-size: 1.5rem;
    text-decoration: none;
    margin: 0 0.8rem;
    color: var(--primary_color);
    position: relative;
    z-index: 1;
    transition: 200ms ease-in-out;
}

.top .top_nav .top_nav-navbar a::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 3px solid white;
    height: 35px;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 350ms ease-in-out;
}

.top .top_nav .top_nav-navbar a:hover::before,
.top .top_nav .top_nav-navbar a:focus::before {
    transform: scaleX(1);
}

.top .top_nav .top_nav-navbar a:hover{
    color: #5EDA33 ;
}

@media only screen and (max-width: 650px) {
    .top_nav {
       padding: 1rem;
    }
}

@media only screen and (max-width: 450px) {
    .top .top_nav .top_nav-navbar a{
        font-family: var(--primary_font);
        font-weight: bold;
        font-size: 0.8rem;
        text-decoration: none;
        margin: 0 0.2rem;
    }

    .top .top_nav .top_nav-navbar a::before{
        height: 25px;
    }
    
}

.homepage {
    max-width: 100%;
    position: relative;
    /* position: relative; */
}

.homepage img {
    width: 100%;
    height: 96vh;
    object-fit: cover;
}

.homepage a img {
    width: 55px;
    height: 55px;
    border: 1px solid rgb(59, 59, 59, 0.7);
    border-radius: 50%;
    background-color: var(--primary_color) !important;
    position: absolute;
    left: 80%;
    bottom: -25px;
    right: 0;
    margin: 0 auto;
    cursor: pointer;
}

.homepage-overlay {
    position: absolute;
    width: 100%;
    height: 96vh;
    background-color: rgb(0, 0, 0, 0.65);
    top: 0;
    display: flex;
    padding-left: 5rem;
    align-items: center;
}

.homepage-overlay .outer {
    color: white;
    font-family: var(--secondary_font);
}

.homepage-overlay .outer h1 {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    position: relative;
    font-size: 4.5rem;
    color: #5EDA33;
    animation: slideHeading_1 2s ease-in-out;
}

.homepage-overlay .outer h2 {
    font-family: var(--secondary_font);
    font-size: 2rem;
    animation: slideHeading_2 2s ease-in-out;
}

/* .homepage-overlay .outer h2 .metal{
    animation: color_float 5s infinite ease-in-out;
} */

@keyframes slideHeading_1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideHeading_2 {
    0% {
        opacity: 0;
    }

    60% {
        opacity: 0;
    }

    70% {
        opacity: 0;
    }

    100% {
        opacity: 1s;
    }
}


@media only screen and (max-width: 950px) {

    .homepage-overlay {
        padding-left: 2em;
    }

    .homepage-overlay .outer h1 {
        font-size: 2.5rem;
        width: 450px;
    }

    .homepage-overlay .outer h2 {
        font-size: 1.5rem;
        width: 400px;
    }
}

@media only screen and (max-width: 450px) {

    .homepage-overlay .outer h1 {
        width: 350px;
    }

    .homepage-overlay .outer h1 {
        font-size: 2rem;
    }

    .homepage-overlay .outer h2 {
        width: 350px;
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 380px) {

    .homepage-overlay .outer h1 {
        font-size: 2rem;
        width: 280px;
    }

    .homepage-overlay .outer h2 {
        width: 200px;
        font-size: 1.2rem;
    }
}


/* @keyframes color_float {
    0%{ 
        transform: translateY(0);
     }
    15%{ 
        transform: translateY(0);
     }
    20%{ 
        transform: translateY(10px);
     }
    35%{ 
        transform: translateY(10px);
     }
    40%{ 
        transform: translateY(5px);
     }
    55%{ 
        transform: translateY(5px);
     }
    60%{ 
        transform: translateY(0);
     }
    75%{ 
        transform: translateY(-10px);
     }
    80%{ 
        transform: translateY(-10px);
     }
    95%{ 
        transform: translateY(-5px);
     }
    100%{ 
        transform: translateY(0px);
     }
} */
.about-wrapper{
padding: 0 6rem;
}

.about-container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 2rem;
}

.about-container:nth-child(even){
    flex-direction: row-reverse;
}

.about-container img{
    width: 400px;
    border-radius: 5px;
    box-shadow: 1px 1px 10px 1px black;
}

.about-container div{
    padding: 0 2rem;
    width: 800px;
}

.about-container div h1{
    font-size: 2rem;
    font-family: var(--secondary_font);
    color: #5EDA33;
}

.about-container div p{
    font-size: 1.2rem;
    font-family: var(--primary_font);
    font-weight: 600;
}

@media only screen and (max-width: 990px) {

    .about-wrapper{
        padding: 0 4rem;
    }

    .about-container img{
        width: 300px;
    }

    .about-container div{
        padding: 0 2rem;
    }

    .about-container div h1{
        font-size: 1.5rem;
    }
    
    .about-container div p{
        font-size: 1rem;
    }
  }

@media only screen and (max-width: 800px) {

    .about-wrapper{
        padding: 0 4rem;
    }

    .about-container img{
        width: 250px;
    }

    .about-container div h1{
        font-size: 1.3rem;
    }
    
    .about-container div p{
        font-size: 0.9rem;
    }
  }
  
@media only screen and (max-width: 700px) {
    .about-container{
        flex-direction: column;
        max-width: 400px;
        margin: 0 auto 2rem;
    }

    .about-container:nth-child(even){
        flex-direction: column;
    }

    .about-container img{
        width: 100%;
        height: 250px;
    }

    .about-container div{
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        padding: 0;
        margin-top: 1.5rem;
    }
  }


.contact {
    width: 100%;
    padding-bottom: 5rem;
}

.contact-heading{
    display: flex;
    justify-content: center;
    margin-top: -1.5rem;
    font-family: var(--secondary_font);
}

.contact .container .row {
    padding: 1rem 2rem 3rem;
}

.contact .container .row > div + div > h1 {
    padding: 5rem 5rem 0;
    text-align: start;
    font-size: 2rem;
    font-family: var(--secondary_font);
    color: #5EDA33; 
}

.contact .container .row > div + div > p {
    padding: 0 5rem;
    text-align: start;
    font-size: 1.2rem;
    font-family: var(--primary_font);
    font-weight: 600;
}

.contact .container .row .contact-img{
    width: 80%;
    animation: floating 3s infinite ease-in-out;
    
}


.contact .container .row div + div{
    text-align: center;
    padding: 1rem;
}

@keyframes floating{
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(-20px);
    }
    100%{
        transform: translateY(0);
    }
}

.contact .container .row .contact-form .input-field {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 0 1rem;
}

.contact .container .row .contact-form .input-field label {
    font-size: 1.1rem;
    font-family: var(--primary_font);
    font-weight: bold;
    color: #2b7114;
}

.contact .container .row .contact-form .send-btn {
    width: 100%;
    font-family: var(--secondary_font);
    font-size: 1.5rem;
    background-color: transparent;
    border: 2px solid #5EDA33;
    color: #5EDA33;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact .container .row .contact-form .send-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 5px;
    bottom: 0;
    z-index: -1;
    background-color: black;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: transform .4s ease-in-out;
}

.contact .container .row .contact-form .send-btn:hover::before,
.contact .container .row .contact-form .send-btn:focus::before {
    transform: scaleY(1);
}

.contact .container .row .contact-form .send-btn:hover,
.contact .container .row .contact-form .send-btn:focus {
    color: #5EDA33;
    border: none;
}

.contact .container .row .contact-form .submit_loader::after {
    position: absolute;
    content: '';
    z-index: -1;
    border: 8px solid #016FB6;
    border-radius: 50%;
    border-top: 8px solid #5EDA33;
    width: 35px;
    height: 35px; /* Safari */
    animation: spin 1.2s linear infinite;
  }
  
  /* Safari */
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  
@media only screen and (max-width: 762px) {
    .contact .container .row {
        flex-direction: column-reverse;
    }

    .contact .container .row div + div {
        padding: 0 0 2rem;
    }

    .contact .container .row  > div + div h1{
        text-align: center;
        padding: 0;
    }
    
    .contact .container .row  > div + div p{
        text-align: center;
        padding: 0;
    }
  }
.copyright{
    /* background-color: #01B2F7; */
    background-color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0;
}

.copyright h1{
    color: white;
    font-family:'Courier New', Courier, monospace;
    font-size: 1rem;
    margin: 0;
    margin-top: 0.3rem;
}

@media only screen and (max-width: 550px) {
    .copyright h1{
        font-size: 0.8rem;
        text-align: center;
    }
  }
.separator {
    width: 100%;
    height: 30px;
    margin-bottom: 25px;
}

.green {
    background-color: #5EDA33

}

.black {
    background-color: rgb(44, 44, 44);

}
.footer{
    background-color: var(--secondary_color);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem 3rem;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    font-family: var(--primary_font);
    font-size: 1rem;
}

.footer .address, .phone{
    display: flex;
    align-items: center;
}

.footer .address img{
    width: 35px;
}

.footer .address h4{
    font-weight: bold;
    margin: 0;
    width: 400px;
    text-align: center;
}

.footer .phone img{
    width: 35px;
}

.footer .phone, h4{
    font-weight: bold;
    font-size: 1rem;
    margin: 0;
}

@media only screen and (max-width: 700px) {
    .footer {
      flex-direction: column;
      text-align: center;

    }
    
    .footer .address h4{
        width: unset;
    }
    
    .footer .address, .phone{
       margin-bottom: 1.5rem;
       width: 90%;
    }

    .footer .phone{
        justify-content: center;
    }


  }

.heading{
    display: flex;
    justify-content: center;
    margin-top: -1.5rem;
    font-family: var(--secondary_font);
    color: black;
    align-items: center;
}

.heading h1{
    font-size: 3rem;
    margin: 2rem 1rem;
}

.heading hr{
    width: 100px;
    height: 2px;
}

@media only screen and (max-width: 450px) {
    .heading h1{
        font-size: 2rem;
        margin: 2rem 1rem;
    }
    .heading hr{
        width: 80px;
        height: 2px;
    }
  }



body, html, #root {
  height: 100%;
}

:root{
  --white: white;
  --primary_color: white;
  --secondary_color: #b7b7b7;
  --tertiary_color: #848484;
  --tetra_color: #5a5a5a;
  --hex_color: #2e2e2e;
  --black: black;
  --primary_font: 'Roboto Slab', serif;
  --secondary_font: 'Bakbak One', cursive;

  --top_nav_color: "#01B2F7"

}


