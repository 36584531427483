.separator {
    width: 100%;
    height: 30px;
    margin-bottom: 25px;
}

.green {
    background-color: #5EDA33

}

.black {
    background-color: rgb(44, 44, 44);

}