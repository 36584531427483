
.contact {
    width: 100%;
    padding-bottom: 5rem;
}

.contact-heading{
    display: flex;
    justify-content: center;
    margin-top: -1.5rem;
    font-family: var(--secondary_font);
}

.contact .container .row {
    padding: 1rem 2rem 3rem;
}

.contact .container .row > div + div > h1 {
    padding: 5rem 5rem 0;
    text-align: start;
    font-size: 2rem;
    font-family: var(--secondary_font);
    color: #5EDA33; 
}

.contact .container .row > div + div > p {
    padding: 0 5rem;
    text-align: start;
    font-size: 1.2rem;
    font-family: var(--primary_font);
    font-weight: 600;
}

.contact .container .row .contact-img{
    width: 80%;
    animation: floating 3s infinite ease-in-out;
    
}


.contact .container .row div + div{
    text-align: center;
    padding: 1rem;
}

@keyframes floating{
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(-20px);
    }
    100%{
        transform: translateY(0);
    }
}

.contact .container .row .contact-form .input-field {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 0 1rem;
}

.contact .container .row .contact-form .input-field label {
    font-size: 1.1rem;
    font-family: var(--primary_font);
    font-weight: bold;
    color: #2b7114;
}

.contact .container .row .contact-form .send-btn {
    width: 100%;
    font-family: var(--secondary_font);
    font-size: 1.5rem;
    background-color: transparent;
    border: 2px solid #5EDA33;
    color: #5EDA33;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact .container .row .contact-form .send-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 5px;
    bottom: 0;
    z-index: -1;
    background-color: black;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: transform .4s ease-in-out;
}

.contact .container .row .contact-form .send-btn:hover::before,
.contact .container .row .contact-form .send-btn:focus::before {
    transform: scaleY(1);
}

.contact .container .row .contact-form .send-btn:hover,
.contact .container .row .contact-form .send-btn:focus {
    color: #5EDA33;
    border: none;
}

.contact .container .row .contact-form .submit_loader::after {
    position: absolute;
    content: '';
    z-index: -1;
    border: 8px solid #016FB6;
    border-radius: 50%;
    border-top: 8px solid #5EDA33;
    width: 35px;
    height: 35px;
    -webkit-animation: spin 1.2s linear infinite; /* Safari */
    animation: spin 1.2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  
@media only screen and (max-width: 762px) {
    .contact .container .row {
        flex-direction: column-reverse;
    }

    .contact .container .row div + div {
        padding: 0 0 2rem;
    }

    .contact .container .row  > div + div h1{
        text-align: center;
        padding: 0;
    }
    
    .contact .container .row  > div + div p{
        text-align: center;
        padding: 0;
    }
  }