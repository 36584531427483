.homepage {
    max-width: 100%;
    position: relative;
    /* position: relative; */
}

.homepage img {
    width: 100%;
    height: 96vh;
    object-fit: cover;
}

.homepage a img {
    width: 55px;
    height: 55px;
    border: 1px solid rgb(59, 59, 59, 0.7);
    border-radius: 50%;
    background-color: var(--primary_color) !important;
    position: absolute;
    left: 80%;
    bottom: -25px;
    right: 0;
    margin: 0 auto;
    cursor: pointer;
}

.homepage-overlay {
    position: absolute;
    width: 100%;
    height: 96vh;
    background-color: rgb(0, 0, 0, 0.65);
    top: 0;
    display: flex;
    padding-left: 5rem;
    align-items: center;
}

.homepage-overlay .outer {
    color: white;
    font-family: var(--secondary_font);
}

.homepage-overlay .outer h1 {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    position: relative;
    font-size: 4.5rem;
    color: #5EDA33;
    animation: slideHeading_1 2s ease-in-out;
}

.homepage-overlay .outer h2 {
    font-family: var(--secondary_font);
    font-size: 2rem;
    animation: slideHeading_2 2s ease-in-out;
}

/* .homepage-overlay .outer h2 .metal{
    animation: color_float 5s infinite ease-in-out;
} */

@keyframes slideHeading_1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideHeading_2 {
    0% {
        opacity: 0;
    }

    60% {
        opacity: 0;
    }

    70% {
        opacity: 0;
    }

    100% {
        opacity: 1s;
    }
}


@media only screen and (max-width: 950px) {

    .homepage-overlay {
        padding-left: 2em;
    }

    .homepage-overlay .outer h1 {
        font-size: 2.5rem;
        width: 450px;
    }

    .homepage-overlay .outer h2 {
        font-size: 1.5rem;
        width: 400px;
    }
}

@media only screen and (max-width: 450px) {

    .homepage-overlay .outer h1 {
        width: 350px;
    }

    .homepage-overlay .outer h1 {
        font-size: 2rem;
    }

    .homepage-overlay .outer h2 {
        width: 350px;
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 380px) {

    .homepage-overlay .outer h1 {
        font-size: 2rem;
        width: 280px;
    }

    .homepage-overlay .outer h2 {
        width: 200px;
        font-size: 1.2rem;
    }
}


/* @keyframes color_float {
    0%{ 
        transform: translateY(0);
     }
    15%{ 
        transform: translateY(0);
     }
    20%{ 
        transform: translateY(10px);
     }
    35%{ 
        transform: translateY(10px);
     }
    40%{ 
        transform: translateY(5px);
     }
    55%{ 
        transform: translateY(5px);
     }
    60%{ 
        transform: translateY(0);
     }
    75%{ 
        transform: translateY(-10px);
     }
    80%{ 
        transform: translateY(-10px);
     }
    95%{ 
        transform: translateY(-5px);
     }
    100%{ 
        transform: translateY(0px);
     }
} */