.copyright{
    /* background-color: #01B2F7; */
    background-color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0;
}

.copyright h1{
    color: white;
    font-family:'Courier New', Courier, monospace;
    font-size: 1rem;
    margin: 0;
    margin-top: 0.3rem;
}

@media only screen and (max-width: 550px) {
    .copyright h1{
        font-size: 0.8rem;
        text-align: center;
    }
  }