.heading{
    display: flex;
    justify-content: center;
    margin-top: -1.5rem;
    font-family: var(--secondary_font);
    color: black;
    align-items: center;
}

.heading h1{
    font-size: 3rem;
    margin: 2rem 1rem;
}

.heading hr{
    width: 100px;
    height: 2px;
}

@media only screen and (max-width: 450px) {
    .heading h1{
        font-size: 2rem;
        margin: 2rem 1rem;
    }
    .heading hr{
        width: 80px;
        height: 2px;
    }
  }

