.footer{
    background-color: var(--secondary_color);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem 3rem;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    font-family: var(--primary_font);
    font-size: 1rem;
}

.footer .address, .phone{
    display: flex;
    align-items: center;
}

.footer .address img{
    width: 35px;
}

.footer .address h4{
    font-weight: bold;
    margin: 0;
    width: 400px;
    text-align: center;
}

.footer .phone img{
    width: 35px;
}

.footer .phone, h4{
    font-weight: bold;
    font-size: 1rem;
    margin: 0;
}

@media only screen and (max-width: 700px) {
    .footer {
      flex-direction: column;
      text-align: center;

    }
    
    .footer .address h4{
        width: unset;
    }
    
    .footer .address, .phone{
       margin-bottom: 1.5rem;
       width: 90%;
    }

    .footer .phone{
        justify-content: center;
    }


  }
